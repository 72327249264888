import { Standard } from "@typebot.io/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

const TypebotPurchase = () => {
    const location = useLocation();
    const [queryParams, setQueryParams] = useState(null);

    useEffect(() => {
        setQueryParams(location.search);
    }, [location]);

    return (
        <>
            {queryParams ? (
                <>
                    <Standard
                        typebot="amplifyus-pro"
                        style={{
                            width: "100vw",
                            height: "100vh",
                            border: "none",
                        }}
                        apiHost="https://typebot-viewer.onimusic.com.br"
                        prefilledVariables={{
                            queryParams: queryParams,
                        }}
                    />
                </>
            ) : (
                <LoadingSpinner />
            )}
        </>
    );
};

export { TypebotPurchase };
